import { z } from 'zod';
import { TenantTag } from './tenant.js';
/**
 * Logto OIDC signing key types, used mainly in REST API routes.
 */
export var LogtoOidcConfigKeyType;
(function (LogtoOidcConfigKeyType) {
    LogtoOidcConfigKeyType["PrivateKeys"] = "private-keys";
    LogtoOidcConfigKeyType["CookieKeys"] = "cookie-keys";
})(LogtoOidcConfigKeyType || (LogtoOidcConfigKeyType = {}));
/**
 * Value maps to config key names in `logto_configs` table. Used mainly in DB SQL related scenarios.
 */
export var LogtoOidcConfigKey;
(function (LogtoOidcConfigKey) {
    LogtoOidcConfigKey["PrivateKeys"] = "oidc.privateKeys";
    LogtoOidcConfigKey["CookieKeys"] = "oidc.cookieKeys";
})(LogtoOidcConfigKey || (LogtoOidcConfigKey = {}));
/**
 * Logto supported signing key algorithms for OIDC private keys that sign JWT tokens.
 */
export var SupportedSigningKeyAlgorithm;
(function (SupportedSigningKeyAlgorithm) {
    SupportedSigningKeyAlgorithm["RSA"] = "RSA";
    SupportedSigningKeyAlgorithm["EC"] = "EC";
})(SupportedSigningKeyAlgorithm || (SupportedSigningKeyAlgorithm = {}));
export const oidcConfigKeyGuard = z.object({
    id: z.string(),
    value: z.string(),
    createdAt: z.number(),
});
export const logtoOidcConfigGuard = Object.freeze({
    [LogtoOidcConfigKey.PrivateKeys]: oidcConfigKeyGuard.array(),
    [LogtoOidcConfigKey.CookieKeys]: oidcConfigKeyGuard.array(),
});
/* --- Logto tenant configs --- */
export const adminConsoleDataGuard = z.object({
    signInExperienceCustomized: z.boolean(),
    organizationCreated: z.boolean(),
    developmentTenantMigrationNotification: z
        .object({
        isPaidTenant: z.boolean(),
        tag: z.nativeEnum(TenantTag),
        readAt: z.number().optional(),
    })
        .optional(),
});
/* --- Logto tenant cloud connection config --- */
export const cloudConnectionDataGuard = z.object({
    appId: z.string(),
    appSecret: z.string(),
    resource: z.string(),
});
export var LogtoTenantConfigKey;
(function (LogtoTenantConfigKey) {
    LogtoTenantConfigKey["AdminConsole"] = "adminConsole";
    LogtoTenantConfigKey["CloudConnection"] = "cloudConnection";
    /** The URL to redirect when session not found in Sign-in Experience. */
    LogtoTenantConfigKey["SessionNotFoundRedirectUrl"] = "sessionNotFoundRedirectUrl";
})(LogtoTenantConfigKey || (LogtoTenantConfigKey = {}));
export const logtoTenantConfigGuard = Object.freeze({
    [LogtoTenantConfigKey.AdminConsole]: adminConsoleDataGuard,
    [LogtoTenantConfigKey.CloudConnection]: cloudConnectionDataGuard,
    [LogtoTenantConfigKey.SessionNotFoundRedirectUrl]: z.object({ url: z.string() }),
});
export const logtoConfigKeys = Object.freeze([
    ...Object.values(LogtoOidcConfigKey),
    ...Object.values(LogtoTenantConfigKey),
]);
export const logtoConfigGuards = Object.freeze({
    ...logtoOidcConfigGuard,
    ...logtoTenantConfigGuard,
});
export const oidcConfigKeysResponseGuard = oidcConfigKeyGuard
    .omit({ value: true })
    .merge(z.object({ signingKeyAlgorithm: z.nativeEnum(SupportedSigningKeyAlgorithm).optional() }));
